import React,{useState,useEffect,useRef}  from "react";
import Layout from "../components/layout";
import FormGenerator from "../components/form/FormGenerator";
import List from "../components/form/List";
import styled from "styled-components";
import Data from "../config/DJs"
import Edit from "../components/form/Edit";
import {BaseSelect,BaseFormField,FormSubmit} from "../styles/Global"
import { GetList } from "../utils/ApiFunctions";
import axios from "axios";
import mainjson from '../config/main';
import readXlsxFile from 'read-excel-file'


const Header = styled.div`
color: #212529;
text-align: left;
font-size: 14px;
font-weight: 300;
box-sizing: border-box;
outline: 0;
display: flex;
flex-wrap: wrap;
background: #fff;
padding: 14px 10px;
box-shadow: 1px 0 20px rgba(0,0,0,.08);
`

const Title = styled.div`
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    font-size: 1.125rem;
    margin-bottom: 0;
    margin-top: 0;
    margin-left:10px;
`
const Right = styled.div`
position:absolute;
right:25px;
`

const ListContainer = styled.div`
  padding-bottom:20px;
`;

const ListTitle = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: inline-block;
  margin-bottom: .5rem;
  font-weight: 400;
`;


const InputContainer = styled.div`
 
`;

const Select = styled(BaseSelect)`

`

const Top = styled.div`
padding:25px;

`

const Container = styled.div`
padding:25px;
background-color:white;
`



export default function Page() {
    const [options, setOptions] = useState([]);
    const [text, setCurrentText] = useState([]);
    const input = useRef();
    const inputRef = useRef();
    const [value, setValue] = useState(0);
    const [imported, setImported] = useState(false);
    const [importing, setImporting] = useState(false);
    const [importasvalue, setimportasValue] = useState(0);
    const filenameRef = useRef("");
    const [active, setActive] = useState(true);

    function changed(e){
        setValue(e.target.value);
        checkActive();
    }

    function importaschanged(e){
        setimportasValue(e.target.value);
        console.log(e.target.value);
    }

    async function parseFile() {
        
        var file = inputRef.current.files[0];
        var done = false;

        filenameRef.current = file.name;

        await readXlsxFile(file).then((rows) => {
            setCurrentText(rows);
        })
        //console.log("na hoi");
        
        //filenameRef.current = file.name;
        // //setCurrentFile(file);
        // console.log(file);
        // var reader = new FileReader();
        // reader.readAsText(file);
  
        // reader.onload = function() {
        //     console.log(reader.result);
        //     setCurrentText(reader.result);
        // };
        // reader.onerror = function() {
        // console.log('there are some problems');
        // };
    }
  

    function kmzchanged(e){ 
        parseFile();
      }

    async function getFeed(){
        let data = await GetList("sections",0,9999999,"section_name asc","",{},-1);
        let optionarray = [];
        console.log("data = ",data);
        for (let i =0;i<data.records.data.length;i++) {
            let section = data.records.data[i];
            optionarray.push({key:section.section_id,value:section.section_name});
        }
        setOptions(optionarray);
    }

    function checkActive(){
        console.log("checkactive ",value,imported);
    }

    async function submit(){
        setImported(false)
        let resultdata = "";
        setImporting(true);
        let body = {
            "type":"import",
            "CMSUser-Token":localStorage.getItem('token'),
            "CMSUser-Agent":navigator.userAgent,
            "KML":JSON.stringify(text),
            "file":filenameRef.current.toLowerCase(),
            "section":value,
            "state":importasvalue
        }
    
        await axios({
            method: "post",
            url: mainjson().import_path,
            data: body,
          })
        .then((response) => {
           if (response.data) {
               resultdata = response.data;
               setImported(true);
               setImporting(false);
           }
        })
        .catch((error) => {
            console.log("error "+error);
        });
    
        return resultdata;
    }

    useEffect(() => {
        getFeed();
    }, []);

    useEffect(() => {
        if (text && (filenameRef.current.toLowerCase().indexOf(".xls") != -1 || filenameRef.current.toLowerCase().indexOf(".csv") != -1) && !importing && value != "") {
            setActive(true);   
            console.log("use effect set active true");
        }
        else {
            setActive(false);
            console.log("use effect set active false");
        }
    }, [text, value,imported,importing]);

    

  return (
    <Layout>
        <Header>
            <Title>
                    Import Excel File
            </Title>

        </Header>
        <Top>
        <Container>
        <ListContainer>
                <ListTitle>Select (default) Section</ListTitle>
                <Select onChange={changed} value={value}>
                    <option value="">None</option>
                {options.map((val, index) => {
                    return <option value={val.key} key={val.key} ref={input}>{val.value}</option>
                })}
                </Select>
            </ListContainer>
            {/* <ListContainer>
                <ListTitle>Import as</ListTitle>
                <Select onChange={importaschanged} value={importasvalue}>
                <option value='0'>Work in progress</option>
                <option value='1'>Active</option>
                <option value='2'>Inactive</option>
                </Select>
            </ListContainer> */}
            <ListContainer>
                <ListTitle>Excel file</ListTitle>
                <BaseFormField type='file' ref={inputRef} onChange={kmzchanged} onInput={kmzchanged}  accept="application/vnd"/>
            </ListContainer>
            <ListContainer>
                {imported && <p>Imported {filenameRef.current}</p>

                }
                {text && active &&
                <FormSubmit onClick={submit}>Import</FormSubmit>
                }
                {importing && " Importing, please wait"

                }
                </ListContainer>
            
        
            </Container>
            </Top>
    </Layout>
  );
}